<template>
  <div class="fullScreen has-text-left customParent">
    <div v-if="!isLoading" class="mainContent">
      <!-- parts and tools -->
      <div v-if="repairStatus === 'parts-and-tools'" class="stepsContainer">
        <!-- repairStatus.progress.slice(-1)[0].status -->
        <div v-if="guide.parts.length > 0">
          <p class="title is-5 mb-2">Parts:</p>
          <ul class="mb-4">
            <li v-for="part in guide.parts" :key="part.url">{{ part.text }}</li>
          </ul>
        </div>
        <p v-else class="title is-5 mb-2">No parts needed.</p>
        <div v-if="guide.tools.length > 0">
          <p class="title is-5 mb-2">Tools:</p>
          <ul class="mb-4">
            <li v-for="tool in guide.tools" :key="tool.url">{{ tool.text }}</li>
          </ul>
        </div>
        <p v-else class="title is-5 mb-2">No tools needed.</p>
        <b-button class="is-info" @click="showChecklist('before')" icon-right="chevron-right">
          <strong>Start Repair</strong>  
        </b-button>
      </div>
      <!-- individual steps -->
      <div v-else-if="repairStatus === 'in-progress'">
        <!-- {{ guide.steps.length }} -->
        <b-progress
          v-if="!showReassembly && guide.steps.length != 0"
          :value="completionPercent"
          size="is-medium"
          type="is-info"
          show-value
          class="progressContainer"
        >{{ currentStep }} of {{ guide.steps.length }}
        </b-progress>
        <p v-else-if="showReassembly && guide.steps.length != 0" class="title is-4">REASSEMBLY</p>
        <!-- was v-else -->
        <div v-if="!showReassembly">
          <individualStep v-if="guide.steps.length == 0" class="stepsContainer" />
          <div v-for="(step, stepIdx) in guide.steps" :key="stepIdx">
            <div v-if="stepIdx+1 == currentStep">
              <individualStep class="stepsContainer" :step="step" />
            </div>
          </div>
        </div>
        <div v-else class="stepsContainer">
          <div v-for="(reStep, reassembleIdx) in guide.steps" :key="reassembleIdx">
            <individualStep :step="reStep" />
          </div>
        </div>
        <div class="navContainer">
          <!-- note button -->
          <div class="noteButton width-100">
            <b-button @click="makeNote()" icon-right="pencil" class="is-warning" expanded>
              <strong>Add note</strong>
            </b-button>
          </div>
          <div class="is-justify-content-flex-start">
            <!-- in guide navigation -->
            <!-- back buttons -->
            <b-button v-if="currentStep == 1" disabled class="is-info is-light">
              <strong>Back</strong>
            </b-button>
            <b-button v-else-if="showReassembly" @click="moveToReassembly(false)" class="is-info is-light">
              <strong>Back</strong>
            </b-button>
            <b-button v-else @click="updateStep(false)" class="is-info is-light">
              <strong>Back</strong>
            </b-button>
            <!-- next buttons -->
            <b-tooltip v-if="currentStep !== guide.steps.length && !scrollStatus" label="Keep scrolling!" >
              <b-button disabled class="is-info" icon-right="chevron-right">
                <strong>Next</strong>
              </b-button>
            </b-tooltip>
            <b-button v-else-if="currentStep !== guide.steps.length" @click="updateStep(true)" class="is-info" icon-right="chevron-right">
              <strong>Next</strong>
            </b-button>
            <!-- for the final step -->
            <b-tooltip v-else-if="currentStep === guide.steps.length && !scrollStatus" label="Keep scrolling!" >
              <b-button class="is-info" disabled>
                <strong>Begin Reassembly</strong>
              </b-button>
            </b-tooltip>
            <b-button v-else-if="currentStep === guide.steps.length && scrollStatus && !showReassembly" class="is-info" @click="moveToReassembly(true)">
              <strong>Begin Reassembly</strong>
            </b-button>
            <b-button v-else-if="showReassembly" @click="showChecklist('after')" class="is-success">
              <strong>Mark Completed</strong>
            </b-button>
          </div>
        </div>
      </div>
      <!-- finished the repair -->
      <div v-else-if="repairStatus === 'finished-repair'" class="stepsContainer finishedSection">
        <div>
          <p class="title is-3 mb-2">You finished the repair.</p>
          <b-button class="is-success is-light mt-4" @click="returnHome()">Return</b-button>
        </div>
      </div>

      <inspectionChecklist v-if="$store.state.showChecklist.value" />

    </div>
  </div>
</template>

<style lang="scss" scoped>
.customParent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mainContent {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-items: center;
  padding: 0 10vw;
  width: 100%;
  max-width: 960px;
}
@media only screen and (max-width: 768px) {
  .mainContent {
    padding: 0;
  }
}

// the fullScreen class makes to 90vh, so we fill it in with these
.progressContainer {
  // together, it adds to 5vh
  height: 2.5vh !important;
  margin: 1.25vh 0 !important; // fixes bulma built in css
}
// the stepsContainer and navContainer should add to 85vh
.stepsContainer {
  height: 72vh !important;
  overflow: scroll;
}
@media only screen and (max-width: 768px) {
  .stepsContainer {
    height: 71vh !important;
  }
}
.navContainer {
  height: 12vh !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}
@media only screen and (max-width: 768px) {
  .navContainer {
    height: 13vh !important;
    // align-items: flex-end;
    justify-content: space-evenly;
  }
}

.finishedSection {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>

<script>
import individualStep from '@/components/individual-step.vue';
import inspectionChecklist from '@/components/inspectionChecklist.vue';

import firebase from 'firebase/app'
import 'firebase/firestore'

import { mapGetters } from "vuex";
import { db } from '@/main';

export default {
  name: "Steps",
  title: 'In Progress',
  components: {
    individualStep,
    inspectionChecklist,
  },
  computed: {
    completionPercent() {
      return Math.round(100 * (this.currentStep / this.guide.steps.length))
    },
    ...mapGetters({
      guide: "getSelectedGuide",
      isLoading: "isLoading",
      repairStatus: "getRepairStatus",
      currentStep: "getCurrentStep",
      scrollStatus: "getScrollStatus",
      showReassembly: "getShowReassembly"
    })
  },
  mounted () {
    this.moveToReassembly(false)
  },
  methods: {
    updateStep(value) {
      if (this.guide.steps.length == 0) {
        this.showChecklist('after')
      } else {
        this.$store.dispatch('updateCurrentStep', value)
      }
    },
    returnHome() {
      this.$store.commit('SET_REPAIR_STATUS', null)
      this.$router.push('devices')
    },
    showChecklist(status) {
      this.$store.commit('SET_SHOW_CHECKLIST', {
        value: true,
        status: status,
      })
    },
    moveToReassembly(value) {
      this.$store.commit('SHOW_REASSEMBLY', value);
    },
    makeNote () {
      this.$buefy.dialog.prompt({
        title: "Make a note",
        message: "This will be logged with the repair",
        confirmText: "Save",
        type: "is-warning",
        inputAttrs: { placeholder: 'e.g. noticed LCD flex cable was torn', },
        onConfirm: (note) => {
          // TODO: add the ifixit guide ID, so that on the dashboard we can pull the guide, then display the specific step. For shop owner reference.
          const ref = db.collection('shops').doc(this.$store.state.user.meta.shop_id).collection('repairLog')
          ref.doc(this.$store.state.repairID).update({
            notes: firebase.firestore.FieldValue.arrayUnion(note),
          })
          .then(this.$buefy.toast.open({ duration: 2500, message: `Saved note`, position: 'is-top' }))
          .catch(this.$buefy.toast.open({ duration: 2500, message: `Failed to save note`, position: 'is-top', type: 'is-danger' }))
        }
      });
    },
  }
};
</script>
