<template>
  <div>
    <b-modal v-model="showChecklist.value" :width="400">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <p class="title is-5">Inspection Checklist</p>
            <div class="scrollMe">
              <p>Inspect the following components and check the switch if the component is damaged.</p>
              <p>Enter any additional notes at the bottom</p>
              <div v-for="(query, idx) in values" :key="idx">
                <b-switch v-model="query.value">{{ query.name }}</b-switch>
              </div>
              <b-input class="mb-2" placeholder="Charge cycles" v-model="chargeCycles" />
              <b-input class="mb-2" placeholder="Charge capacity" v-model="chargeCapacity" />
              <b-input class="mb-2" placeholder="Contact number" v-model="contactNumber" />
              <b-input class="mb-2" placeholder="Passcode" v-model="passcode" />
              <textarea v-model="notes" class="textarea" placeholder="Additional notes" />
              <div id="scrollEnd" />
            </div>
            <div class="navContainer">
              <!-- <b-tooltip v-if="!scrollStatus" label="Keep scrolling!" class="is-flex">
                <b-button disabled expanded icon-right="chevron-right">
                  <strong>Start</strong>
                </b-button>
              </b-tooltip> -->
              <!-- was v-else -->
              <div>
                <b-button v-if="showChecklist.status === 'before'" @click="showNamePrompt = true;" class="is-info" expanded icon-right="chevron-right">
                  <strong>Start</strong>
                </b-button>
                <b-button v-else @click="finish()" class="is-info" expanded icon-right="chevron-right">
                  <strong>Finish Repair</strong>
                </b-button>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="showNamePrompt" :width="300">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <p class="title is-5">Customer Name</p>
            <p>Enter the customer's name. This will be logged.</p>
            <b-input v-model="customerName" placeholder="Customer name" class="mb-3" />
            <b-button v-if="customerName.length == 0" expanded icon-right="chevron-right" class="is-info" disabled>
              <strong>Start</strong>
            </b-button>
            <b-button v-else expanded icon-right="chevron-right" class="is-info" @click="startRepair()">
              <strong>Start</strong>
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
.scrollMe {
  max-height: 60vh!important;
  overflow: scroll;
}
.navContainer {
  margin-top: 1.5rem;
}
</style>

<script>
import { mapGetters } from 'vuex';
// let ref;
// let observer = null;

export default {
  name: 'inspectionChecklist',
  computed: {
    ...mapGetters({
      // scrollStatus: "getScrollStatus",
      currentStep: "getCurrentStep",
      guide: "getSelectedGuide",
      showChecklist: "getShowChecklist",
    })
  },
  // mounted() {
  //   // the scroll watcher
  //   ref = document.getElementById("scrollEnd");
  //   observer = new IntersectionObserver(
  //     (entries, observer) => {
  //       entries.forEach(entry => {
  //         if (entry.isIntersecting) {
  //           this.$store.commit("SET_SCROLL_STATUS", true);
  //           observer.unobserve(entry.target);
  //         }
  //       });
  //     },
  //     { threshold: 0.05 }
  //   );
  //   setTimeout(() => { // prevents observing while page is loading, which caused immediately marking scroll as done
  //     observer.observe(ref);
  //   }, 500)
  // },
  // beforeDestroy() { // since all individual steps are pre rendered, this is only fired on the last step
  //   this.$store.commit("SET_SCROLL_STATUS", false);
  //   observer.unobserve(ref);
  // },
  methods: {
    startRepair() {
      this.$buefy.toast.open({ duration: 2500, message: `Repair started`, position: 'is-top' })
      const msg = 'in-progress';
      const object = {
        repair: this.guide.title,
        customer: this.customerName,
        progress: [{ 'status': msg, 'date': new Date() }],
        status: msg,
        currentStep: this.currentStep,
        totalSteps: this.guide.steps.length,
      };
      object[`${this.showChecklist.status}-repair`] = {
        values: this.values,
        notes: this.notes
      };
      this.$store.dispatch('updateRepairStatus', object);
      this.showNamePrompt = false;
      this.$store.commit('SET_SHOW_CHECKLIST', false);
      this.notes = ''; // reset notes after writing it
    },
    finish() {
      const msg = 'finished-repair';
      let object = {
        progress: { 'status': msg, 'date': new Date() },
        status: msg,
      };
      object[`${this.showChecklist.status}-repair`] = {
        values: this.values,
        notes: this.notes
      };
      this.$store.dispatch('updateRepairStatus', object);
      this.$store.commit('SET_SHOW_CHECKLIST', false);
    }
  },
  data () {
    return {
      showNamePrompt: false,
      customerName: '',
      values: [
        { name: 'Frame', value: false },
        { name: 'Scratches on back glass', value: false },
        { name: 'Glass/scratches', value: false },
        { name: 'Digitizer (touch)', value: false },
        { name: 'LCD', value: false },
        { name: 'Battery', value: false },
        { name: 'Charge port', value: false },
        { name: 'Home button', value: false },
        { name: 'Fingerprint scanner', value: false },
        { name: 'Power button', value: false },
        { name: 'Volume buttons', value: false },
        { name: 'Front camera', value: false },
        { name: 'Back camera', value: false },
        { name: 'Proximity sensor', value: false },
        { name: 'Top speaker', value: false },
        { name: 'Loud speaker', value: false },
        { name: 'Microphone', value: false },
        { name: 'Cellular 4G', value: false },
        { name: 'Wifi', value: false },
        { name: 'Bluetooth', value: false },
        { name: 'Screen rotation', value: false },
        { name: 'Headphone jack', value: false },
        { name: 'Adhesive', value: false },
        { name: 'Water damage', value: false },
      ],
      notes: '',
      chargeCycles: '',
      chargeCapacity: '',
      passcode: '',
      contactNumber: '',
    }
  },
}
</script>