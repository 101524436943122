<template>
  <div>
    <p v-if="step" class="title">{{ step.title }}</p>

    <div v-if="!step">
      <div v-html="guide.introduction_rendered" />
    </div>

    <div v-if="step">
      <div class="is-flex is-flex-direction-column is-align-items-center">
        <div v-for="media in step.media.data" :key="media.id">
          <img :src="media.medium" />
        </div>
      </div>
      <div v-for="(line, lineIdx) in step.lines" :key="lineIdx">
        <p v-html="renderedText(line.text_rendered)" class="mb-2"></p>
      </div>
    </div>

    <div id="scrollEnd" />

  </div>
</template>



<script>
import { mapGetters } from 'vuex';
let ref;
let observer = null;

export default {
  name: "individual-step",
  title: "In Progress",
  props: ["step"],
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters({
      guide: "getSelectedGuide",
    })
  },
  mounted() {
    // the scroll watcher
    ref = document.getElementById("scrollEnd");
    observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.$store.commit("SET_SCROLL_STATUS", true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );
    setTimeout(() => { // prevents observing while page is loading, which caused immediately marking scroll as done
      observer.observe(ref);
    }, 500)
  },
  beforeDestroy() { // since all individual steps are pre rendered, this is only fired on the last step
    this.$store.commit("SET_SCROLL_STATUS", false);
    observer.unobserve(ref);
  },
  methods: {
    renderedText(text) {
      return `- ${text}`;
    },
  }
};
</script>